import pickBy from 'lodash/pickBy';
import { useHistory, useLocation } from 'react-router';

import qs from '../../libs/querystring';

type UseQueryParamsResult = {
    params: any;
    replace(query);
    push(query, reset?: boolean);
    remove(...keys);
};

export function useUrlQuery(toLowerCase = true): UseQueryParamsResult {
    const location = useLocation();
    const history = useHistory();

    const queryParams = qs.parse(location.search, toLowerCase) || {};

    return {
        params: queryParams,
        replace(query) {
            if (!query.page) {
                query.page = 1;
            }
            query = { ...queryParams, ...query };
            const nextUrl = qs.format(
                location.pathname,
                pickBy(query, (val) => !!val),
            );
            history.push(nextUrl);
        },
        push(query, reset = false) {
            if (!query.page) {
                query.page = 1;
            }
            if (!reset) {
                query = { ...queryParams, ...query };
            }
            const nextUrl = qs.format(
                location.pathname,
                pickBy(query, (val) => !!val),
            );
            history.push(nextUrl);
        },
        remove(...keys) {
            const query = { ...queryParams, page: 1 };
            for (const key of keys) {
                delete query[key];
            }
            delete query.page;
            const nextUrl = qs.format(location.pathname, query);
            history.push(nextUrl);
        },
    };
}

export default {
    useQueryParams: useUrlQuery,
};
