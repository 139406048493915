import { IShareConfig } from './interface';

const rootDomain = 'sounmate.com';
const schema = 'https://';
export const config: IShareConfig = {
    rootDomain,
    schema,
    API_ENV: 'prod',
    cdn: {
        imageBaseUrl: `${schema}image-yto.${rootDomain}`,
        videoBaseUrl: `${schema}file.${rootDomain}`,
        fileBaseUrl: `${schema}file.${rootDomain}`,
        h5app: `${schema}h5.${rootDomain}`,
    },
    api: {
        client_version_api: `${schema}client-version-manager.${rootDomain}`,

        identity_api_v1: `${schema}identity-api.${rootDomain}/1`,
        content_api_v1: `${schema}content-api.${rootDomain}/1`,
        remind_api_v1: `${schema}remind-api.${rootDomain}/1`,
        management_api_v1: `${schema}management-api.${rootDomain}/1`,
        chat_api_v1: `${schema}chat-api.${rootDomain}/1`,
        live_api: `${schema}live.${rootDomain}`,

        ordering_api_v1: `${schema}ordering-api.${rootDomain}/1`,
        marketing_api_v1: `${schema}marketing-api.${rootDomain}/1`,
        payment_api_v1: `${schema}payment-api.${rootDomain}/1`,
        member_api_v1: `${schema}member-api.${rootDomain}/1`,
    },
};

if (process.env.API_ENV === 'dev') {
    console.warn('将使用DEV环境的api');
    config.api.identity_api_v1 = `${schema}dev-identity-api.${rootDomain}/1`;
    config.api.content_api_v1 = `${schema}dev-content-api.${rootDomain}/1`;
    config.api.remind_api_v1 = `${schema}dev-remind-api.${rootDomain}/1`;
    config.api.management_api_v1 = `${schema}dev-management-api.${rootDomain}/1`;
    config.api.chat_api_v1 = `${schema}dev-chat-api.${rootDomain}/1`;

    config.api.live_api = `${schema}dev-live.${rootDomain}`;

    config.api.ordering_api_v1 = `${schema}dev-ordering-api.${rootDomain}/1`;
    config.api.marketing_api_v1 = `${schema}dev-marketing-api.${rootDomain}/1`;
    config.api.payment_api_v1 = `${schema}dev-payment-api.${rootDomain}/1`;
    config.api.member_api_v1 = `${schema}dev-member-api.${rootDomain}/1`;
}

export default config;
