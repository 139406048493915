export class KnownError extends Error {
    isKnown = true;

    [prop: string]: any;
    constructor(message, data?) {
        super(message);

        for (const name in data) {
            this[name] = data[name];
        }
    }
}

export class ApiError extends KnownError {
    isApi = true;
    error;
    isValidError = false;
    status;
    description = '';
    constructor(public response, public raw_ex) {
        super('ApiError');
        const conf = response.config;
        this.description = `[${conf.method}]${conf.url}`;
        const defaultMsg = `[Server]Something went wrong...`;
        const { status, data } = response;
        this.status = status;
        if (status == 403 || status == 401) {
            this.message = `Permission denied[${status}]`;
            return;
        }
        this.isKnown = false;
        if (status == 400) {
            const message = `Request Invalid[400]`;
            this.message = message;
            this.error = data.error;
            this.isValidError = true;
            this.isKnown = false;
            return;
        }
        if (data && data.error) {
            const error = data.error;
            this.code = error.code;
            this.error = error;
            if (error.code != 0) {
                this.message = error.message || `[${this.code}]${defaultMsg}`;
            } else {
                this.message = error.message || defaultMsg;
            }
        } else {
            this.message = defaultMsg;
        }
    }
}
