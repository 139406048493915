import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

import di from '../../../libs/di';
import { Events } from '../../../libs/events';
import { ILogger, logger } from '../../../libs/logger';

dayjs.extend(calendar);
const startupTime = new Date();

@di.Register('timeService')
export class TimeService extends Events implements ITimeService {
    @logger() logger: ILogger;

    private clientTime = startupTime.getTime();
    private serverTime = startupTime.getTime();

    private subCount = 0;

    private timer;

    private startTick() {
        this.logger.info('计时器已开始');
        this.timer = setInterval(() => {
            this.trigger('tick', this.getServerNow());
        }, 1000);
    }

    private tryStartTick() {
        if (this.subCount == 0) {
            this.startTick();
        }
        this.subCount += 1;
    }

    private tryStopTick() {
        if (this.subCount > 0) {
            this.subCount -= 1;
        }
        if (this.subCount == 0) {
            clearInterval(this.timer);
            this.logger.info('计时器已停止');
        }
    }

    sync(serverNow) {
        this.clientTime = new Date().getTime();
        this.serverTime = new Date(serverNow).getTime();
        this.trigger('sync');
        this.logger.info('时间已同步');
    }

    getServerNow() {
        const now = new Date().getTime();
        return this.serverTime + now - this.clientTime;
    }

    onTick(callback: (nowMs: number) => void) {
        let sub = this.on('tick').then(callback);
        this.tryStartTick();
        return () => {
            this.tryStopTick();
            sub && sub.off();
            sub = null;
        };
    }

    //#region helper

    fromNow(milliseconds) {
        const now = this.getServerNow();
        return dayjs(milliseconds).diff(now, 's');
    }

    calculateTime(inputSeconds, isShowDay = false) {
        let [day, hours, minutes, seconds] = [0, 0, 0, 0];
        if (inputSeconds > 0) {
            day = isShowDay ? Math.floor(inputSeconds / (60 * 60 * 24)) : 0;
            hours = Math.floor(inputSeconds / (60 * 60)) - day * 24;
            minutes = Math.floor(inputSeconds / 60) - day * 24 * 60 - hours * 60;
            seconds =
                Math.floor(inputSeconds) - day * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
        }
        return {
            isEnd: inputSeconds <= 0,
            day,
            hours,
            minutes,
            seconds,
        };
    }

    calendar(target) {
        const now = this.getServerNow();
        // const diff = dayjs(now).diff(target, 's');
        // if (diff < 10) {
        //     return `刚刚`;
        // }
        // if (diff < 60) {
        //     return `${diff}秒之前`;
        // }
        // if (diff < 60 * 60) {
        //     return `${Math.ceil(diff / 60)}分钟之前`;
        // }
        // if (diff < 60 * 60 * 24) {
        //     return dayjs(target).format('HH:mm');
        // }
        // if (diff < 60 * 60 * 24 * 365) {
        //     return dayjs(target).format('MM/DD');
        // }
        // return dayjs((target / 60) * 60 * 24 * 365) + '年之前';
        return dayjs(target).calendar(now, {
            sameDay: 'h:mm a', // The same day ( Today at 2:30 AM )
            nextDay: '[Tomorrow at] h:mm a', // The next day ( Tomorrow at 2:30 AM )
            nextWeek: 'next dddd [at] h:mm a', // The next week ( Sunday at 2:30 AM )
            lastDay: '[Yesterday at] h:mm a', // The day before ( Yesterday at 2:30 AM )
            lastWeek: 'ddd [at] h:mm a', // Last week ( Last Monday at 2:30 AM )
            sameElse: 'DD/MM/YYYY', // Everything else ( 7/10/2011 )
        });
    }
    //#endregion
}

export default TimeService;
