import sharedConfig from '@/shared/config';

export const config = {
    ...sharedConfig,
};

if (process.env.NODE_ENV === 'development') {
    config.api.identity_api_v1 = '/identity-api/1';
    config.api.content_api_v1 = '/content-api/1';
    config.api.chat_api_v1 = '/chat-api/1';
    config.api.marketing_api_v1 = '/marketing-api/1';
    config.api.payment_api_v1 = '/payment-api/1';
    config.api.ordering_api_v1 = '/ordering-api/1';
    config.api.remind_api_v1 = '/remind-api/1';
    config.api.member_api_v1 = '/member-api/1';
}

export default config;
