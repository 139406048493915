import AuthComponent from '@/components/AuthComponent';

import AsyncComponent from '../components/AsyncComponent';
import { ConnectLogin } from './Connect/Login';

// import AuthComponent from '../components/AuthComponent';

const routes = [
    {
        path: '/',
        exact: true,
        component: AsyncComponent(() => import('./Home')),
    },
    {
        path: '/connect/login',
        component: ConnectLogin,
    },
    {
        path: '/terms_of_service',
        component: AsyncComponent(() => import('./TermsOfService')),
    },
    {
        path: '/privacy_policy',
        component: AsyncComponent(() => import('./PrivacyPolicy')),
    },
    {
        path: '/community_guidelines',
        component: AsyncComponent(() => import('./CommunityGuidelines')),
    },

    {
        path: '/mate/:id',
        component: AsyncComponent(() => import('./Mate')),
    },

    {
        path: '/cashout/result',
        component: AsyncComponent(() => import('./Cashout/Result')),
    },
    {
        path: '/cashout/:mkey',
        component: AsyncComponent(() => import('./Cashout/Payout')),
    },
    {
        path: '/cashout',
        component: AsyncComponent(() => import('./Cashout')),
    },
    {
        path: '/',
        component: AuthComponent(require('../layouts/_Layout').default),
        routes: [
            {
                path: '/products/:activeId?',
                component: AsyncComponent(() => import('./Products')),
            },
            {
                path: '/payout/result',
                component: AsyncComponent(() => import('./Payout/Result')),
            },
            {
                path: '/payout/:view?',
                component: AsyncComponent(() => import('./Payout')),
            },
            {
                component: require('./NotFound').default,
            },
        ],
    },
    {
        component: require('./NotFound').default,
    },
];
export default routes;
