import React from 'react';
import { DialogContext, IDialog } from 'react-dialog-context';

import styles from './CustomDialog.module.less';

interface IProps {
    dialog: IDialog;
}

export const CustomDialog: React.FC<IProps> = (props: IProps) => {
    const dialog = props.dialog;
    let content;
    if (React.isValidElement(dialog.owner)) {
        content = dialog.owner;
    } else {
        content = <dialog.owner dialog={dialog} {...dialog.activationData} />;
    }

    return (
        <DialogContext.Provider value={{ dialog }}>
            <div key={dialog.id} className={styles.modal} style={{ zIndex: dialog.zIndex }}>
                {content}
            </div>
        </DialogContext.Provider>
    );
};

export default CustomDialog;
