import dayjs from 'dayjs';

import IdentityApi from '../../../api/identity';
import di from '../../../libs/di';
import { ILogger, logger } from '../../../libs/logger';
import MemoryCache from '../../../libs/MemoryCache';
import ConfigService from '../config';
import { IConfigService } from '../config/interface';
import { ITokenService, TokenObject } from './interface';

type SavedTokenObject = {
    data: TokenObject;
    autoLogin: boolean;
    time: number;
    v: string;
};

const tokenVersion = 'v1';
const enableRefreshToken = true;
@di.Register('tokenService')
export class TokenService implements ITokenService {
    @logger() private logger: ILogger;

    @di.Property(IdentityApi) private identityApi: IdentityApi;

    @di.Property(ConfigService) private configService: IConfigService;

    constructor(private storage_key = 'access_token') {}

    private delayTimer;

    private delaySave() {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            this.forceSave();
        }, 2000);
    }

    private async forceSave() {
        await this.configService.saveAsync(this.storage_key, this.tokenObj);
        this.logger.info('本地存储更新', this.tokenObj);
    }

    private tokenObj: SavedTokenObject = null;

    private async checkAndRefreshToken(nowDayjs) {
        const { autoLogin, time, v } = this.tokenObj;
        let data = this.tokenObj.data;
        if (!enableRefreshToken) {
            return data;
        }
        const expiresTime = dayjs.unix(time).add(data.expires, 'second');
        let isRefreshToken = v != tokenVersion;
        //token已过期
        if (expiresTime.isBefore(nowDayjs)) {
            this.logger.info('Token已过期');
            await this.clear();
            return null;
        }
        //Token过期时间剩余1/5的时候刷新token
        const refreshTokenSpan = -(data.expires / 5);
        if (expiresTime.add(refreshTokenSpan, 'second').isBefore(nowDayjs) && autoLogin) {
            this.logger.info('Token即将过期,用户已开启自动登录,本次操作将刷新Token');
            isRefreshToken = true;
        }
        if (isRefreshToken) {
            try {
                // 请求服务器更换新的token
                if (data.refresh_token) {
                    const res = await this.identityApi.connectToken().post({
                        grant_type: 'refresh_token',
                        client_id: data.client_id,
                        refresh_token: data.refresh_token,
                    });
                    data = { ...res.data, client_id: data.client_id };
                    this.tokenObj = {
                        autoLogin,
                        data,
                        v: tokenVersion,
                        time: nowDayjs.unix(),
                    };
                    this.delaySave();
                } else {
                    throw new Error('刷新Token不可用');
                }
            } catch (ex) {
                await this.clear();
                data = null;
            }
        }
        return data;
    }

    /**
     * 读取本地保存的 access_token
     */
    private async read() {
        if (!this.tokenObj) {
            this.tokenObj = await this.configService.readAsync<SavedTokenObject>(this.storage_key);
        }
        if (this.tokenObj) {
            const { autoLogin, time } = this.tokenObj;
            const nowDayjs = dayjs();
            const data = await MemoryCache.onceAsync('TokenServiceRefreshToken', () => {
                return this.checkAndRefreshToken(nowDayjs);
            });
            if (autoLogin) {
                return data;
            }
            //客户端过期
            const expireDayjs = dayjs.unix(time).add(20, 'minute');
            if (expireDayjs.isBefore(nowDayjs)) {
                await this.clear();
                return null;
            }
            this.tokenObj = {
                autoLogin,
                data,
                v: tokenVersion,
                time: nowDayjs.unix(),
            };
            this.delaySave();
            return data;
        }
        return null;
    }

    async clear() {
        this.tokenObj = null;
        return this.configService.removeAsync(this.storage_key);
    }

    async getAccessToken() {
        const token = await this.read();
        if (!token) {
            return null;
        }
        return token.access_token;
    }

    async getSecurityHeaders() {
        const token = await this.read();
        if (token) {
            const { token_type, access_token } = token;
            return {
                Authorization: `${token_type} ${access_token}`,
            };
        }
        return null;
    }
    /**
     * 将 access_token 保存到本地
     * @param obj
     * @param autoLogin
     */
    save(obj, autoLogin = false) {
        const time = dayjs().unix();
        this.tokenObj = {
            data: obj,
            autoLogin,
            v: tokenVersion,
            time,
        };
        return this.forceSave();
    }
}

export default TokenService;
