import axios from 'axios';
import keys from 'lodash/keys';

import { ApiError, KnownError } from '../../common/error';
import di from '../../libs/di';
import Logger from '../../libs/logger';
import { TimeService } from '../../services/common/time';

function procResponseHeader(headers) {
    const headerKeys = keys(headers);
    for (let i = 0; i < headerKeys.length; i++) {
        const key = headerKeys[i];
        if (/^date$/i.test(key)) {
            const timeService = di.getInstance<TimeService>(TimeService);
            timeService.sync(headers[key]);
            return;
        }
    }
}

export function setupAxios() {
    axios.interceptors.response.use(
        response => {
            procResponseHeader(response.headers);
            return response;
        },
        error => {
            if (error.code == 'ECONNABORTED') {
                if (/timeout of (\d+)ms exceeded/.test(error.message)) {
                    return Promise.reject(new KnownError('timeout'));
                }
            }
            if (error.message == 'Network Error') {
                return Promise.reject(new KnownError('Network Error'));
            }
            if (error.response) {
                procResponseHeader(error.response.headers);
                const res = error.response;
                if (res.status === 304) {
                    return res;
                }
                if (res.status == 403 || res.status == 401) {
                    return Promise.reject(new KnownError(`Permission denied[${res.status}]`));
                }
                return Promise.reject(new ApiError(res, error));
            }
            return Promise.reject(error);
        },
    );
}

const logger = Logger.tag('http');
export function enableHttpLogs(enable = true) {
    axios.interceptors.request.use(config => {
        if (enable) {
            logger.info(
                `开始请求:${config.method} --> ${config.url} data: `,
                config.data || config.params || {},
                'headers',
                config.headers,
            );
        }
        return config;
    });
    axios.interceptors.response.use(
        response => {
            if (enable) {
                const config = response.config;
                logger.info(`结束请求:${config.method} <-- ${config.url} result: `, response.data);
            }
            return response;
        },
        error => {
            // logger.info('请求错误', error.response, error);
            return Promise.reject(error);
        },
    );
    logger.info('适配器注册成功');
}
export default setupAxios;
