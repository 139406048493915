import { useCallback, useEffect, useRef, useState } from 'react';

export function useDidMount(fn: () => void) {
    useEffect(fn, []);
}

export function useDidUnmount(fn: () => void) {
    useEffect(() => {
        return () => {
            fn();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export function useDidUpdate(fn: () => void) {
    useEffect(fn);
}
export function useIsMounted() {
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);
    return isMounted;
}

export function useToggle(initialValue: any, onChange?: (arg0: boolean) => void) {
    const [isOn, setIsOn] = useState(initialValue);
    const toggle = () => {
        const nextValue = !isOn;
        setIsOn(nextValue);
        if (onChange) {
            onChange(nextValue);
        }
    };
    return [isOn, useCallback(toggle, [isOn, onChange])];
}

export default {
    useDidMount,
    useDidUpdate,
    useIsMounted,
};
