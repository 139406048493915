import React from 'react';
import { useHistory } from 'react-router';

import { useDidMount } from '@/shared/hooks/common';
import { useUrlQuery } from '@/shared/hooks/querystring';
import di from '@/shared/libs/di';
import SessionService from '@/shared/services/common/session';

interface IConnectTokenProps {}

export const ConnectLogin: React.FC<IConnectTokenProps> = (props: IConnectTokenProps) => {
    const { params } = useUrlQuery();
    const history = useHistory();
    useDidMount(() => {
        const { access_token, next } = params;
        if (access_token) {
            const sessionService = di.getInstance<SessionService>(SessionService);

            sessionService
                .loginByAccessToken(
                    {
                        client_id: '',
                        token_type: 'Bearer',
                        expires: 720000,
                        refresh_token: null,
                        access_token: access_token,
                    },
                    true,
                )
                .then(() => {
                    history.replace(next);
                })
                .catch((ex) => {
                    history.replace('/');
                });
        } else {
            history.replace('/');
        }
    });
    return <></>;
};

export default ConnectLogin;
