import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { setupAxios } from '@/shared/setups/http/axios';

import AppProvider from './components/AppProvider';
import GlobalDialogHost from './components/GlobalDialogHost/host';
import './di/config';
import Pages from './pages';
import './styles/App.css';

setupAxios();

export default function App() {
    return (
        <AppProvider>
            <BrowserRouter>
                <Pages />
            </BrowserRouter>
            <GlobalDialogHost />
        </AppProvider>
    );
}
