import isArray from 'lodash/isArray';

import { ISyncKeyValueStorage } from '../ISyncKeyValueStorage';

/* eslint-disable @typescript-eslint/no-explicit-any */

export class LocalSyncKeyValueStorage implements ISyncKeyValueStorage {
    constructor(private __STORE_NAME__) {
        if (!__STORE_NAME__) {
            throw new Error('必须设置存储名称');
        }
    }

    keys() {
        const len = sessionStorage.length;
        const keys = [];
        for (let i = 0; i < len; i++) {
            const key = sessionStorage.key(i);
            const [name, _key] = key.split('/');
            if (name == this.__STORE_NAME__) {
                keys.push(_key);
            }
        }
        return keys;
    }

    get(key: string, defaultValue = null): any {
        if (/\//.test(key)) {
            throw new Error("key不能包含'/'");
        }
        const _key = `${this.__STORE_NAME__}/${key}`;
        return sessionStorage.getItem(_key) || defaultValue;
    }

    set(key: string, value: any) {
        if (/\//.test(key)) {
            throw new Error("key不能包含'/'");
        }
        const _key = `${this.__STORE_NAME__}/${key}`;
        sessionStorage.setItem(_key, value);
        return value;
    }

    batchSetObject(obj: any, keyProp?: any, valueProp?: any) {
        if (isArray(obj)) {
            for (const item of obj) {
                const key = item[keyProp];
                if (!key || key == '') {
                    continue;
                }
                let val = item;
                if (valueProp) {
                    val = item[valueProp];
                }
                this.setObject(key, val);
            }
        } else {
            for (const key in obj) {
                this.setObject(key, obj[key]);
            }
        }
    }

    setObject(key: string, value: any): any {
        this.set(key, JSON.stringify(value));
        return value;
    }

    getObject(key: string): any {
        const str = this.get(key, null);
        if (str) {
            return JSON.parse(str);
        }
        return null;
    }

    setObjectProperty(key: string, propertyName: string, value: any) {
        let obj = this.getObject(key);
        if (!obj) {
            obj = {};
        }
        obj[propertyName] = value;
        return this.setObject(key, obj);
    }

    getObjectProperties(key: string, ...propertyNames: any[]) {
        let obj = this.getObject(key);
        if (!obj) {
            obj = {};
        }
        const result = {};
        for (const propertyName of propertyNames) {
            result[propertyName] = obj[propertyName];
        }
        return result;
    }

    getObjectValue(key: string, propertyName: string) {
        const obj = this.getObject(key);
        if (obj) {
            return obj[propertyName];
        }
        return null;
    }

    remove(key: string) {
        const _key = `${this.__STORE_NAME__}/${key}`;
        sessionStorage.removeItem(_key);
    }

    batchRemove(keys) {
        for (const key of keys) {
            this.remove(key);
        }
    }
    clear() {
        const keys: any[] = this.keys();
        const removeKeys: any[] = [];
        for (const key of keys) {
            const name = key.split('/')[0];
            if (name == this.__STORE_NAME__) {
                removeKeys.push(key);
            }
        }
        this.batchRemove(removeKeys);
        return keys;
    }
}

export default LocalSyncKeyValueStorage;
