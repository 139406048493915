import React from 'react';
import { renderRoutes } from 'react-router-config';

interface ILayoutProps {
    route: any;
}

export const Layout: React.FC<ILayoutProps> = (props: ILayoutProps) => {
    const { route } = props;
    return renderRoutes(route.routes);
};

export default Layout;
