import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import di from '@/shared/libs/di';
import SessionService from '@/shared/services/common/session';

export function AuthComponent(Component) {
    const sessionService = di.getInstance<SessionService>(SessionService);
    const AuthComponent: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
        return useObserver(() => {
            // const { location } = props;
            if (sessionService.isAuthenticated == false) {
                // const backUrl = location.pathname + location.search;
                return <Redirect to={`/`}></Redirect>;
            }
            if (sessionService.isAuthenticated == true) {
                return <Component {...props} />;
            }
            return <></>;
        });
    };
    return AuthComponent;
}

export default AuthComponent;
