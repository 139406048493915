import React, { useEffect } from 'react';
import { dialog, IDialog } from 'react-dialog-context';
import ReactDOM from 'react-dom';

import CustomDialog from './CustomDialog';

interface IDialogHostProps {
    name?: string;
}

export const GlobalDialogHost: React.FC<IDialogHostProps> = (props: IDialogHostProps) => {
    const name = props.name || 'default';
    useEffect(() => {
        dialog.addContext(name, {
            initialZIndex: 10000,
            async addHost(theDialog: IDialog) {
                const { id } = theDialog;
                const elId = `melcyfk68-${id}`;
                const el = document.createElement('div');
                el.id = elId;
                document.body.append(el);
                ReactDOM.render(<CustomDialog dialog={theDialog} />, el);
            },
            async removeHost(theDialog: IDialog) {
                const { id } = theDialog;
                const elId = `melcyfk68-${id}`;
                const el = document.getElementById(elId);
                ReactDOM.unmountComponentAtNode(el);
                el.remove();
            },
        });
        return () => {
            dialog.removeContext(name);
        };
    }, [name]);
    return <></>;
};

export default GlobalDialogHost;
